import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subscription } from 'rxjs';
import { CompaniesDataProvider } from './data-providers/companies.data-provider';
import { distinctUntilChanged, single } from 'rxjs/operators';
import { Company } from '../models/company.model';
import { User } from '../models/user.model';

@Injectable()
export class CompanyService implements OnInit {
  private companyId$ = new ReplaySubject<number>(1);
  public isCompaniesLoaded = false;
  public currentActiveCompanies = [];
  public currentCompany$ = new BehaviorSubject<Company | undefined>(undefined);
  CURRENT_USER: User = JSON.parse(localStorage.getItem('current_user'));
  COMPANY_ID: number;

  constructor(private companiesDataProvider: CompaniesDataProvider) {
    this.COMPANY_ID = this.CURRENT_USER && this.CURRENT_USER.company && this.CURRENT_USER.company.id;
    this.getPersistedCompanyId();
  }

  ngOnInit() {
    if (this.COMPANY_ID !== null) {
      this.companyId$.next(this.COMPANY_ID);
    }
    this.getCompanyIdSubscription(companyId => {
      this.updateCompanyInfo(companyId);
    });
  }

  private updateCompanyInfo(companyId: number) {
    this.companiesDataProvider
      .getCompany(companyId)
      .pipe(single())
      .subscribe(company => {
        this.currentCompany$.next(company);
      });
  }

  setCompanyId(id: number) {
    this.companiesDataProvider.getCompanies().subscribe({
      next: response => {
        const selectedCompany = response.companies.find(company => company.id === id);

        localStorage.setItem(
          'current_user',
          JSON.stringify({
            ...JSON.parse(localStorage.getItem('current_user')),
            company: selectedCompany
          })
        );

        this.COMPANY_ID = id;
        this.CURRENT_USER = {
          ...JSON.parse(localStorage.getItem('current_user')),
          company: selectedCompany
        };
        this.companyId$.next(id);
      }
    });
  }

  getCompanyIdSubscription(cb: (companyId: number) => void): Subscription {
    return this.companyId$.pipe(distinctUntilChanged()).subscribe(cb);
  }

  updateUserInfo() {
    this.CURRENT_USER = JSON.parse(localStorage.getItem('current_user'));
  }

  private getPersistedCompanyId() {
    this.companiesDataProvider
      .getCompanies()
      .pipe(single())
      .subscribe(response => {
        localStorage.setItem(
          'current_user',
          JSON.stringify({
            ...JSON.parse(localStorage.getItem('current_user')),
            company: response.companies[0]
          })
        );
        this.COMPANY_ID = response.companies[0].id;
      });
  }

  get isEcommerceAvailable(): boolean {
    return this.CURRENT_USER.company.e_commerce_available;
  }
}
