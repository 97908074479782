import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { LoaderService } from '../loader.service';
import { Meal } from '../../models/meal.model';
import { Menu } from '../../models/menu.model';
import { CommonDataProvider, ICommonList, ICommonResponse } from './common.data-provider';
import { CompanyService } from '../company.service';
import { transformMealToSave, transformMealToShow } from '../../../shared/helpers/menusmeals.data-transformer';
import { TaxRatesResponse } from '../../models/tax-rate.model';

@Injectable()
export class MenusmealsDataProvider extends CommonDataProvider<Menu> {
  private mealsUrl = environment.apiUrl + '/admin/meals';
  private menusUrl = environment.apiUrl + '/admin/companies/';
  private taxRatesUrl = environment.apiUrl + '/admin/tax';
  private addonsUrl = environment.apiUrl + '/admin/addons';
  private orderTypesMetadataUrl = environment.apiUrl + '/admin/meals/restriction-metadata';

  constructor(http: HttpClient, private companyService: CompanyService, loaderService: LoaderService) {
    super(http, loaderService);
  }

  getMenu(id: number): Observable<Menu> {
    return this.getItem(`${this.menusUrl + this.companyService.COMPANY_ID}/menus/${id}`);
  }

  getMenus(): Observable<ICommonList<Menu>> {
    return this.getList(`${this.menusUrl + this.companyService.COMPANY_ID}/menus`);
  }

  updateMenu(id: number, menu: Partial<Menu>) {
    menu.company = { id: 11 };
    menu.id = id;
    return this.putAction(`${this.menusUrl + this.companyService.COMPANY_ID}/menus/${id}`, menu);
  }

  saveMenu(menu: Partial<Menu>): Observable<Menu> {
    menu.company = { id: 11 };
    return this.postAction(`${this.menusUrl + this.companyService.COMPANY_ID}/menus`, menu);
  }

  deleteMenu(menuId: number): Observable<Menu> {
    return this.deleteAction<Menu>(`${this.menusUrl + this.companyService.COMPANY_ID}/menus/${menuId}`);
  }

  hideMenu(id: number) {
    return this.postAction(`${this.menusUrl + this.companyService.COMPANY_ID}/menus/${id}/hide`);
  }

  copyMeal(id: number) {
    return this.postAction(`${this.mealsUrl}/${id}/copy`);
  }

  showMenu(id: number) {
    return this.postAction(`${this.menusUrl + this.companyService.COMPANY_ID}/menus/${id}/activate`);
  }

  getMeals(cafe_id?: string): Observable<Meal[]> {
    const params: { [key: string]: string } = {
      company_id: this.companyService.COMPANY_ID.toString()
    };

    if (cafe_id) {
      params.cafe_id = cafe_id;
    }

    return this.http.get(this.mealsUrl, { params }).pipe(
      map((response: ICommonResponse) => {
        if (Array.isArray(response.data)) {
          return response.data.map(meal => transformMealToShow(meal)) as Meal[];
        }
        return [];
      }),
      catchError(this.handleError)
    );
  }

  getMeal(id: number | string): Observable<Meal> {
    this.loaderService.show();
    return this.http.get(`${this.mealsUrl}/${id}`).pipe(
      map((response: ICommonResponse) => {
        this.loaderService.hide();
        return transformMealToShow(response.data);
      }),
      catchError(this.handleError)
    );
  }

  createMeal(meal: Partial<Meal>): Observable<Meal> {
    meal.company = { id: this.companyService.COMPANY_ID };
    meal = transformMealToSave(meal);
    return this.postAction(this.mealsUrl, meal).pipe(map(data => transformMealToShow(data)));
  }

  updateMeal(meal: Meal, transformPrices = true) {
    meal.company = { id: this.companyService.COMPANY_ID };
    if (transformPrices) {
      meal = transformMealToSave(meal);
    }
    return this.putAction(`${this.mealsUrl}/${meal.id}`, meal).pipe(map(data => transformMealToShow(data)));
  }

  deleteMeal(id: number) {
    return this.deleteAction<Menu>(`${this.mealsUrl}/${id}`);
  }

  hideMeal(id: number) {
    return this.postAction(`${this.mealsUrl}/${id}/hide`);
  }

  showMeal(id: number) {
    return this.postAction(`${this.mealsUrl}/${id}/activate`);
  }

  saveAddonsOrderInAddonGroupForMeal(groupId: number, order: number[]) {
    return this.postAction(`${this.addonsUrl}/groups/items/order?group_id=${groupId}`, order);
  }

  saveMealsOrderInCategory(categoryId: number, mealsOrders: number[]) {
    return this.http.post(`${this.mealsUrl}/order?category_id=${categoryId}`, mealsOrders).pipe(catchError(this.handleError));
  }

  getTaxRates(): Observable<TaxRatesResponse> {
    return this.http
      .get(`${this.taxRatesUrl}/${this.companyService.COMPANY_ID}`, {
        responseType: 'json'
      })
      .pipe(
        first(),
        map((resp: TaxRatesResponse) => resp),
        catchError(this.handleError)
      );
  }

  getPossibleOrderTypes(): Observable<{
    [title: string]: { name: string };
  }> {
    return this.http.get(this.orderTypesMetadataUrl, { responseType: 'json' }).pipe(
      first(),
      map((resp: { data: { [title: string]: { name: string } } }) => resp.data),
      catchError(this.handleError)
    );
  }
}
