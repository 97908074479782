import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then(mod => mod.LoginModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./modules/signup/signup.module').then(mod => mod.SignupModule)
  },
  {
    path: 'recovery',
    loadChildren: () => import('./modules/recovery/recovery.module').then(mod => mod.RecoveryModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./modules/main/main.module').then(mod => mod.MainModule)
  },
  {
    path: 'screen/*',
    loadChildren: () => import('./modules/screen/screen.module').then(mod => mod.ScreenModule)
  },
  { path: '', redirectTo: 'admin', pathMatch: 'full' },
  { path: '**', redirectTo: 'admin/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })]
})
export class AppRoutingModule {}
