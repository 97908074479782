import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { StompConfig, StompService } from '@stomp/ng2-stompjs';

import { AppComponent } from './app.component';
import { AuthenticationInterceptor } from './core/interceptors/auth.interceptor';
import { LoaderService } from './core/services/loader.service';
import { LoginService } from './core/services/login.service';
import { CategoriesDataProvider } from './core/services/data-providers/categories.data-provider';

import { CoffeeHouseDataProvider } from './core/services/data-providers/coffe-house.data-provider';
import { CompaniesDataProvider } from './core/services/data-providers/companies.data-provider';
import { CompanyService } from './core/services/company.service';
import { MerchantsDataProvider } from './core/services/data-providers/merchants.data-provider';
import { FileUploadService } from './core/services/file-upload.service';
import { MenusmealsDataProvider } from './core/services/data-providers/menusmeals.data-provider';
import { ModalService } from './core/services/modal.service';
import { OffersDataProvider } from './core/services/data-providers/offers.data-provider';
import { DeliveryRatesService } from './core/services/delivery-rates.service';
import { OrdersDataProvider } from './core/services/data-providers/orders.data-provider';
import { PicturesDataProvider } from './core/services/data-providers/pictures.data-provider';
import { ProductsDataProvider } from './core/services/data-providers/products.data-provider';
import { PushNotificationDataProvider } from './core/services/data-providers/push-notification.data-provider';
import { ReportsService } from './core/services/reports.service';
import { stompConfig } from './shared/helpers/stomp-ws/stomp.config';
import { UserAgreementDataProvider } from './core/services/data-providers/user-agreement.data-provider';
import { UsersDataProvider } from './core/services/data-providers/users.data-provider';
import { LoyaltyProgramsDataProvider } from './core/services/data-providers/loyalty-program.data-provider';
import { LoyaltyCardsDataProvider } from './core/services/data-providers/loyalty-card.data-provider';

import { DeliveryDaysDataProvider } from './core/services/data-providers/delivery-days.data-provider';
import { AddonsDataProvider } from './core/services/data-providers/addons.data-provider';
import { RecoveryService } from './core/services/recovery.service';
import { DeliveryZoneDataProvider } from './core/services/data-providers/delivery-zone.data-provider';
import { AppRoutingModule } from './app.routing.module';
import { CompanyOptionsDataProvider } from './core/services/data-providers/company-options.data-provider';
import { CompanyOptionNamesDataProvider } from './core/services/data-providers/company-option-names.data-provider';

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, BrowserAnimationsModule, HttpClientModule, RouterModule, AppRoutingModule],
  providers: [
    CoffeeHouseDataProvider,
    FileUploadService,
    UsersDataProvider,
    OffersDataProvider,
    DeliveryRatesService,
    ProductsDataProvider,
    ModalService,
    LoginService,
    RecoveryService,
    LoaderService,
    CompanyService,
    UserAgreementDataProvider,
    PushNotificationDataProvider,
    OrdersDataProvider,
    MenusmealsDataProvider,
    CompaniesDataProvider,
    MerchantsDataProvider,
    DeliveryDaysDataProvider,
    DeliveryZoneDataProvider,
    PicturesDataProvider,
    CategoriesDataProvider,
    AddonsDataProvider,
    ReportsService,
    LoyaltyProgramsDataProvider,
    LoyaltyCardsDataProvider,
    CompanyOptionsDataProvider,
    CompanyOptionNamesDataProvider,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true
    },
    StompService,
    {
      provide: StompConfig,
      useValue: stompConfig()
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
