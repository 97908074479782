import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';

import { CommonDataProvider, ICommonResponse } from './common.data-provider';
import { LoaderService } from 'src/app/core/services/loader.service';
import { LoyaltyCard } from '../../models/loyalty-card.model';
import { CompanyService } from '../company.service';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class LoyaltyCardsDataProvider extends CommonDataProvider<LoyaltyCard> {
  private cardsUrl = `${environment.apiUrl}/admin/loyalty-cards`;

  constructor(http: HttpClient, private companyService: CompanyService, loaderService: LoaderService) {
    super(http, loaderService);
  }

  getCard(user_id: string): Observable<LoyaltyCard> {
    const params = {
      user_id,
      company_id: this.companyService.COMPANY_ID.toString()
    };
    return this.http.get(this.cardsUrl, { params }).pipe(
      map((response: ICommonResponse) => response.data),
      catchError(this.handleError)
    );
  }
}
