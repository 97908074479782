export const DICTIONARY = {
  state: {
    NEW: 'Pending for approval',
    REJECTED: 'Rejected',
    CANCELED: 'Canceled',
    ACCEPTED: 'Approved',
    CLOSED: 'Finished',
    PREPARED: 'Prepared'
  },
  direction: {
    TAKEAWAY: 'TAKEAWAY',
    DELIVERY: 'DELIVERY'
  }
};
