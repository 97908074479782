import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { UserAgreement } from '../../models/user-agreement.model';
import { CommonDataProvider } from './common.data-provider';

@Injectable()
export class UserAgreementDataProvider extends CommonDataProvider<UserAgreement> {
  private agreementUrl = environment.apiUrl + '/admin/agreement';

  getAgreement(): Observable<UserAgreement> {
    return this.http.get(this.agreementUrl).pipe(
      map((data: UserAgreement) => data),
      catchError(this.handleError)
    );
  }

  updateAgreement(agreenment: UserAgreement) {
    const body = JSON.stringify(agreenment);
    return this.patchAction(this.agreementUrl, body);
  }
}
