import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { CommonDataProvider, ICommonResponse } from './common.data-provider';
import { LoaderService } from 'src/app/core/services/loader.service';
import { CompanyService } from '../company.service';

import { LoyaltyProgram } from '../../models/loyalty-program.model';
import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class LoyaltyProgramsDataProvider extends CommonDataProvider<LoyaltyProgram> {
  private programsUrl = `${environment.apiUrl}/admin/loyalty-programs`;
  public actions = {
    start: 'start',
    stop: 'stop',
    close: 'close'
  };

  constructor(http: HttpClient, private companyService: CompanyService, loaderService: LoaderService) {
    super(http, loaderService);
  }

  createProgram(program: LoyaltyProgram) {
    program.companyId = this.companyService.COMPANY_ID;
    return this.http.post(this.programsUrl, program).pipe(
      map((data: ICommonResponse) => data.data),
      catchError(this.handleError)
    );
  }

  getProgram(id: string) {
    return this.getItem(`${this.programsUrl}/${id}`);
  }

  updateProgram(program: LoyaltyProgram) {
    program.companyId = this.companyService.COMPANY_ID;
    return this.http.put(this.programsUrl, program);
  }

  updateState(id: string, action: string): Observable<LoyaltyProgram> {
    return this.http.post(`${this.programsUrl}/${id}/${action}`, {}).pipe(
      map((response: ICommonResponse) => {
        return response.data;
      }),
      catchError(this.handleError)
    );
  }

  deleteProgram(id: string) {
    return this.http.delete(`${this.programsUrl}/${id}`);
  }

  getPrograms(company_id: string, cafe_id?: string) {
    const params: { company_id: string; cafe_id?: string } = { company_id };
    if (cafe_id) {
      params.cafe_id = cafe_id;
    }

    return this.getList(this.programsUrl, params);
  }
}
