export enum DeliveryZoneRuleType {
  Blacklist = 'BLACKLIST',
  Whitelist = 'WHITELIST',
  Standalone = 'STANDALONE'
}

export enum DeliveryZonePermission {
  Create = 'create',
  Delete = 'delete',
  Mode = 'mode',
  Show = 'show',
  Update = 'update'
}

export interface IUnsavedDeliveryZone {
  name: string;
  rules: (IUnsavedDeliveryZoneRule | ISavedDeliveryZoneRule)[];
}

export interface IUnsavedPostalCodeZone extends IUnsavedDeliveryZone {
  postalCodeMax: number;
  postalCodeMin: number;
}

export interface IUnsavedGeoZone extends IUnsavedDeliveryZone {
  shape: google.maps.LatLngLiteral[][][];
  rate?: number;
}

export interface ISavedDeliveryZone {
  id: number;
  name: string;
  permission: Record<DeliveryZonePermission, boolean>;
  rules: ISavedDeliveryZoneRule[];
}

export interface ITypedDeliveryZone extends ISavedDeliveryZone {
  type: DeliveryZoneTypes;
}

export type ITypedPostalCodeZone = ITypedDeliveryZone & IUnsavedPostalCodeZone;
export type ITypedGeoZone = ITypedDeliveryZone & IUnsavedGeoZone;

export enum DeliveryZoneTypes {
  ECommerce = 'eCommerce',
  Geospatial = 'geospatial',
  PostalCodes = 'postalCodes',
}

export interface IUnsavedDeliveryZoneListRule {
  type: DeliveryZoneRuleType.Blacklist | DeliveryZoneRuleType.Whitelist;
  placeId?: string | null; // represents street name here
  postalCodeMax: number;
  postalCodeMin: number;
}

export interface IUnsavedDeliveryZoneStandaloneRule {
  type: DeliveryZoneRuleType.Standalone;
  placeId: string;
}

export type IUnsavedDeliveryZoneRule = IUnsavedDeliveryZoneListRule | IUnsavedDeliveryZoneStandaloneRule;

export type ISavedDeliveryZoneRule = IUnsavedDeliveryZoneRule & { id: number };

export enum ModalModes {
  Decision = 'decision',
  PostalCodes = 'postalCodes',
}
