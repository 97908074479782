import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { LoaderService } from '../loader.service';
import { CommonDataProvider } from './common.data-provider';
import { map, switchMap, tap } from 'rxjs/operators';
import { CompanyOption, ICompanyOption } from '../../models/company-option.model';
import { Pagination } from '../../models/pagination.model';

@Injectable()
export class CompanyOptionsDataProvider extends CommonDataProvider<ICompanyOption> {
  private baseUrl = (companyId: number) => environment.apiUrl + `/admin/companies/${companyId}/options`;
  private optionUrl = (companyId: number, companyOptionId: number) => this.baseUrl(companyId) + `/${companyOptionId}`;

  constructor(http: HttpClient, loaderService: LoaderService) {
    super(http, loaderService);
  }

  getCompanyOptions(companyId: number): Observable<CompanyOption[]> {
    const container: CompanyOption[] = [];
    const getPage = (page: number) =>
      this.http.get(this.baseUrl(companyId), { params: { page: '' + page } }) as Observable<Pagination<ICompanyOption>>;
    const getPagesStartingFrom = (page: number): Observable<CompanyOption[]> =>
      getPage(page).pipe(
        tap(data => container.push(...data.data.map(v => new CompanyOption(v)))),
        switchMap(data => {
          if (data.meta.current_page >= data.meta.last_page) {
            return of(container);
          }
          return getPagesStartingFrom(page + 1);
        })
      );

    this.loaderService.show();
    return getPagesStartingFrom(1).pipe(tap(() => this.loaderService.hide()));
  }

  createCompanyOption(companyId: number, data: Partial<CompanyOption>): Observable<CompanyOption> {
    return this.postAction<CompanyOption>(this.baseUrl(companyId), data.toApi());
  }

  getCompanyOption(companyId: number, companyOptionId: number): Observable<CompanyOption> {
    return this.getItem(this.optionUrl(companyId, companyOptionId)).pipe(map(v => new CompanyOption(v)));
  }

  toggleCompanyOptionActivation(companyId: number, compamyOptionId: number) {
    return this.postAction(this.optionUrl(companyId, compamyOptionId) + '/toggle');
  }

  updateCompanyOption(companyId: number, companyOptionId: number, data: Partial<CompanyOption>): Observable<CompanyOption> {
    return this.putAction<ICompanyOption>(this.optionUrl(companyId, companyOptionId), data.toApi()).pipe(map(v => new CompanyOption(v)));
  }

  deleteCompanyOption(companyId: number, companyOptionId: number) {
    return this.deleteAction(this.optionUrl(companyId, companyOptionId));
  }
}
