import { DateTime } from 'luxon';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { LoaderService } from '../loader.service';
import { CommonDataProvider } from './common.data-provider';
import { CompanyService } from '../company.service';
import { DeliveryDaysModel, DeliverySlot, DeliverySlotTemplatesForWeekday } from '../../models/delivery-days.model';
import { catchError, map, pluck } from 'rxjs/operators';
import { timestampToStringFormat } from '../../../shared/helpers/helpers';

@Injectable()
export class DeliveryDaysDataProvider extends CommonDataProvider<DeliveryDaysModel> {
  constructor(http: HttpClient, private companyService: CompanyService, loaderService: LoaderService) {
    super(http, loaderService);
  }

  getGeneratedSlots(cafeId: number, from: number) {
    const fromDate = timestampToStringFormat(from);
    return this.http
      .get(`${environment.apiUrl}/admin/cafes/${cafeId}/delivery-days?from=${fromDate}`)
      .pipe(catchError(this.handleError), pluck('data'));
  }

  createDeliverySlot(cafeId: number, slot: DeliverySlot) {
    return this.postAction(`${environment.apiUrl}/admin/cafes/${cafeId}/delivery-slots`, slot);
  }

  updateDeliverySlot(cafeId: number, slot: DeliverySlot) {
    return this.putAction(`${environment.apiUrl}/admin/cafes/${cafeId}/delivery-slots`, slot);
  }

  deleteDeliverySlot(slotId: number) {
    return this.http.request('delete', `${environment.apiUrl}/admin/delivery-slots/${slotId}`);
  }

  generateDeliverySlotsByTemplates(cafeId: number, from: number, to: number) {
    const fromDate = timestampToStringFormat(from);
    const toDate = timestampToStringFormat(to);
    return this.postAction(`${environment.apiUrl}/admin/cafes/${cafeId}/delivery-slots/generate?from=${fromDate}&to=${toDate}`);
  }

  getTemplatesByCafe(cafeId: number): Observable<DeliverySlotTemplatesForWeekday[]> {
    return this.http.get(`${environment.apiUrl}/admin/cafes/${cafeId}/delivery-day-templates`).pipe(
      map((response: { data: DeliverySlotTemplatesForWeekday[] }) => response.data),
      catchError(this.handleError)
    );
  }

  saveTemplatesForCafe(data: DeliverySlotTemplatesForWeekday[]) {
    return this.putAction(`${environment.apiUrl}/admin/delivery-day-templates`, data);
  }

  deleteRangeTemplates(cafeId: number, from: Date, to: Date) {
    const fromDate = DateTime.fromJSDate(from).toFormat('yyyy-LL-dd');
    const skip = true;
    let url = `${environment.apiUrl}/admin/cafes/${cafeId}/delivery-slots?skip_booked=${skip}&from=${fromDate}`;
    if (to) {
      const toDate = DateTime.fromJSDate(to).toFormat('yyyy-LL-dd');
      url = `${url}&to=${toDate}`;
    }
    return this.deleteAction(url).pipe(catchError(this.handleError));
  }
}
