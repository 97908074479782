import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { CommonDataProvider } from './data-providers/common.data-provider';

@Injectable()
export class FileUploadService extends CommonDataProvider<any> {
  private imageUrl = environment.apiUrl + '/admin/offers/upload-image'; // TODO: change to user independant URL.

  postFile(fileToUpload: File): Observable<string> {
    const formData: FormData = new FormData();
    formData.append('photo', fileToUpload, fileToUpload.name);
    return this.http.post(this.imageUrl, formData).pipe(
      map((response: any) => {
        const imagePath = response.offer_image;
        // some issee with image relevant path
        if (imagePath.startsWith('/var/www/mrespresso-java-api/public')) {
          return imagePath.replace('/var/www/mrespresso-java-api/public', environment.apiUrl);
        }
        return imagePath;
      }),
      catchError(e => this.handleError(e))
    );
  }

  b64toFile(dataURI): File {
    // convert the data URL to a byte string
    const byteString = atob(dataURI.split(',')[1]);

    // pull out the mime type from the data URL
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // Convert to byte array
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // Create a blob that looks like a file.
    let fileName = 'file';
    const blob = new File([ab], fileName, { type: mimeString });

    // Figure out what extension the file should have
    switch (blob.type) {
      case 'image/jpeg':
        fileName += '.jpg';
        break;
      case 'image/png':
        fileName += '.png';
        break;
    }
    // cast to a File
    return new File([ab], fileName, { type: mimeString });
  }

  makeRandomName(length: number): string {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }
}
