export enum PaymentMerchants {
  MONDIDO = 'mondido',
  UNITELLER = 'uniteller',
  SWISH = 'swish'
}

export interface IUnsavedUnitellerMerchant {
  merchant_id: string;
  login: string;
  pos_id: string;
  pos_name: string;
  password: string;
  tax_mode_code: number;
  payment_gateway: PaymentMerchants.UNITELLER;
}

export interface IUnitellerMerchant {
  id: number;
  merchant_id: string;
  login: string;
  pos_id: string;
  pos_name: string;
  tax_mode: {
    code: number;
    description: string;
  };
  payment_gateway: PaymentMerchants.UNITELLER;
}

export interface IUnsavedMondidoMerchant {
  merchant_id?: string;
  secret: string;
  password: string;
  payment_gateway: PaymentMerchants.MONDIDO;
}

export interface IMondidoMerchant {
  id: number;
  merchantId: string;
}

export interface IUnsavedSwishMerchant {
  payee_alias: string;
  key_password: string;
  payment_gateway: PaymentMerchants.SWISH;
}

export interface ISwishMerchant {
  id: number;
  payee_alias: string;
  merchant_cert_uploaded: boolean;
  server_tls_cert_uploaded: boolean;
  merchant_cert_key_uploaded: boolean;
}

export interface IMerchantsInfo {
  mondido?: IMondidoMerchant;
  uniteller?: IUnitellerMerchant;
  swish?: ISwishMerchant;
}

export interface IAvailableGateway {
  id: PaymentMerchants;
  description: string;
}
