import { translateDateToString, translateStringToDate } from '../../shared/helpers/helpers';

export interface ICompanyOption {
  id: number;
  type: string;
  hidden: boolean;
  start_date: string;
  end_date?: string;
}

export class CompanyOption {
  id: number;
  type: string;
  hidden: boolean;
  start_date: Date;
  end_date?: Date;

  constructor(obj?: Partial<ICompanyOption>) {
    if (obj) {
      this.apply(obj);
    }
  }

  apply(obj: Partial<ICompanyOption>) {
    const { id, type, hidden, start_date, end_date } = obj;
    const applyObj = {
      id,
      type,
      hidden,
      start_date: start_date ? translateStringToDate(start_date) : new Date(),
      end_date: end_date ? translateStringToDate(end_date) : undefined
    };
    for (const prop in applyObj)
      if (applyObj[prop] === undefined) {
        delete applyObj[prop];
      }
    Object.assign(this, applyObj);
  }

  toApi() {
    const { id, type, hidden, start_date, end_date } = this;
    const result: ICompanyOption = { id, type, hidden, start_date: translateDateToString(start_date) };
    if (end_date) {
      result.end_date = translateDateToString(end_date);
    }
    return result;
  }
}

export interface CompanyOptionName {
  title: string;
  value: string;
}
