import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, throwError as observableThrowError } from 'rxjs';
import { catchError, map, pluck } from 'rxjs/operators';
import { StompService } from '@stomp/ng2-stompjs';

import { environment } from '../../../environments/environment';
import { webSocketHeaders } from '../../shared/helpers/stomp-ws/stomp.config';
import { Company } from 'src/app/core/models/company.model';

interface UserData {
  token: {
    access_token: string;
    token_type: string;
  };
  user: {
    admin: boolean;
    email: string;
    first_name: string;
    id: number;
    last_name: string;
    show_customer_data: boolean;
    super_manager: boolean;
  };
  company?: Company;
}

@Injectable()
export class LoginService {
  private offersUrl = `${environment.apiUrl}/admin/tokens`;
  private signupUrl = `${environment.apiUrl}/admin/signup`;
  private onboardingUrl = `${environment.apiUrl}/admin/companies/onboarding`;
  private userChangedSubject = new Subject<void>();
  public isUserLoggedInByCredentials = false;

  constructor(private http: HttpClient, private rxStompService: StompService) {}

  private handleError(error: HttpErrorResponse | any) {
    let errMsg: string;
    if (error instanceof HttpErrorResponse) {
      const body = error;
      const err = body.error || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }

    return observableThrowError(errMsg);
  }

  private handleSuccess(response: UserData) {
    this.isUserLoggedInByCredentials = true;
    localStorage.setItem('token', response.token.access_token);
    if (response.company) {
      localStorage.setItem(
        'current_user',
        JSON.stringify({
          ...response.user,
          company: response.company
        })
      );
    } else {
      localStorage.setItem('current_user', JSON.stringify(response.user));
    }
    this.userChangedSubject.next();
    this.rxStompService.initAndConnect();
    this.rxStompService.configure({
      connectHeaders: webSocketHeaders()
    });
  }

  login(email: string, password: string) {
    const body = JSON.stringify({ email, password });
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http
      .post(this.offersUrl, body, { headers })
      .pipe(pluck('data'), map(this.handleSuccess.bind(this)), catchError(this.handleError));
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('current_user');
    localStorage.removeItem('filtered_companies');
    this.rxStompService.disconnect();
    window.location.reload();
  }

  signup(user: any) {
    return this.http.post(this.signupUrl, user).pipe(pluck('data'), map(this.handleSuccess.bind(this)), catchError(this.handleError));
  }

  getUserChangedSubject() {
    return this.userChangedSubject;
  }

  getRegistrationData(token: string) {
    return this.http
      .get(`${this.onboardingUrl}`, {
        params: {
          token
        }
      })
      .pipe(map(this.handleSuccess.bind(this)), catchError(this.handleError));
  }
}
