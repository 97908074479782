import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, pluck } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { LoaderService } from '../loader.service';
import { getNextStateForOrder, Order, RejectRequest } from '../../models/order.model';
import { RefundRequest } from '../../models/refund.model';
import { CommonDataProvider } from './common.data-provider';
import { CompanyService } from '../company.service';
import { DICTIONARY } from '../../models/orders.dictionary';
import { PaginationMeta } from '../../models/pagination.model';
import { OrderTab } from '../../models/order-tab.model';

@Injectable()
export class OrdersDataProvider extends CommonDataProvider<Order> {
  private ordersUrl = `${environment.apiUrl}/admin/orders`;

  constructor(http: HttpClient, private companyService: CompanyService, loaderService: LoaderService) {
    super(http, loaderService);
  }

  getOrders(
    page: number,
    filter: string,
    tab: OrderTab,
    fromAllCompanies: boolean = false,
    cafeId?: number
  ): Observable<{
    orders: Order[];
    pagination: PaginationMeta;
  }> {
    const ordersPerPage = 20;
    this.loaderService.show();

    const params: { [key: string]: string } = {
      tab,
      filter,
      company_id: this.companyService.COMPANY_ID + '',
      page: page + '',
      per: ordersPerPage + '',
      cafe_id: cafeId ? cafeId + '' : ''
    };

    if (fromAllCompanies) {
      delete params.company_id;
      delete params.cafe_id;
    }

    return this.http
      .get<{ data: Order[]; meta: any }>(this.ordersUrl, { params })
      .pipe(
        map(({ data, meta }) => {
          this.loaderService.hide();
          return {
            orders: data.map(this.normalize),
            pagination: meta
          };
        }),
        catchError(err => {
          this.loaderService.hide();
          return this.handleError(err);
        })
      );
  }

  getOrder = (id: number): Observable<Order> => {
    return this.http.get<{ data: Order }>(`${this.ordersUrl}/${id}`).pipe(pluck('data'));
  };

  normalize(order: Order): Order {
    return {
      ...order,
      created_at: order.created_at ? new Date(order.created_at).toLocaleString() : '',
      state: DICTIONARY.state[order.state],
      preparation_time: order.preparation_time || 0,
      delivery_date: order.delivery_date || ''
    };
  }

  setNextState(order: Order): Observable<Order> {
    const action = getNextStateForOrder(order);
    return this.postAction<Order>(`${this.ordersUrl}/${order.id}/${action}`);
  }

  finishOrder(order: Order): Observable<Order> {
    return this.postAction<Order>(`${this.ordersUrl}/${order.id}/close`);
  }

  rejectOrder(id: number, rejectRequest: RejectRequest): Observable<any> {
    return this.postAction(`${this.ordersUrl}/${id}/reject`, rejectRequest);
  }

  updateOrder(order: Order, updateData: Partial<Order>): Observable<Order> {
    for (const item in updateData) {
      if (updateData[item] === order[item]) {
        delete updateData[item];
      }
    }
    if (!(Object.keys(updateData).length === 0 && updateData.constructor === Object)) {
      return this.putAction<Order>(`${this.ordersUrl}/${order.id}/`, updateData).pipe(map(this.normalize));
    } else {
      return of(order);
    }
  }

  updateOrderPreparationTime(id: number, preparationTime: number): Observable<Order> {
    return this.putAction<Order>(`${this.ordersUrl}/${id}/preparation_time`, { preparation_time: preparationTime }).pipe(
      map(this.normalize)
    );
  }

  updateOrderDeliveryDate(id: number, difference: number): Observable<Order> {
    return this.putAction<Order>(`${this.ordersUrl}/${id}/delivery-date`, {
      difference
    }).pipe(map(this.normalize));
  }

  makeRefund(orderId: number, refundRequest: RefundRequest) {
    return this.http.post(`${this.ordersUrl}/${orderId}/partly-refund`, refundRequest);
  }

  updateTrackNumber(trackNumber: string, orderId: number) {
    return this.http
      .put(`${environment.apiUrl}/admin/orders/${orderId}/tracking-number`, {
        tracking_number: trackNumber
      })
      .pipe(map((res: { data: Order[] }) => res.data));
  }
}
