import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { delay, distinctUntilChanged, map, scan } from 'rxjs/operators';

@Injectable()
export class LoaderService {
  public isShowSpinner$: Observable<boolean>;
  private isShowSpinnerSubject: BehaviorSubject<boolean>;

  constructor() {
    this.isShowSpinnerSubject = new BehaviorSubject<boolean>(false);

    const subject = new Subject<boolean>();
    this.isShowSpinner$ = subject.asObservable();

    this.isShowSpinnerSubject
      .pipe(
        scan((acc, value) => {
          value ? acc++ : acc--;
          if (acc < 0) {
            acc = 0;
          }
          return acc;
        }, 0),
        map(acc => {
          return acc > 0;
        }),
        distinctUntilChanged(),
        delay(0) // To prevent this one https://blog.angular-university.io/angular-debugging/
      )
      .subscribe(value => subject.next(value));
  }

  show() {
    this.isShowSpinnerSubject.next(true);
  }

  hide() {
    setTimeout(() => {
      this.isShowSpinnerSubject.next(false);
    }, 0);
  }
}
