import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { LoaderService } from '../loader.service';
import { Cafe } from '../../models/cafe.model';
import { FulfillmentMetadata } from '../../models/fulfillment-metadata.model';
import { Manager, ManagerInfoToCreate } from '../../models/manager.model';
import { CafeStatistic } from '../../models/statistics.model';
import { CommonDataProvider, ICommonList } from './common.data-provider';
import { CompanyService } from '../company.service';
import { FileUploadService } from '../file-upload.service';

interface ICommonResponse {
  data: any;
}

interface ISearchResponse {
  features: any;
}
export const transformCafeToShow = (cafe): Cafe => {
  return {
    ...cafe,
    delivery_price: cafe.delivery_price / 100,
    min_free_delivery_total: cafe.min_free_delivery_total / 100
  };
};

export const transformCafeToSave = (cafe): Cafe => {
  return {
    ...cafe,
    delivery_price: cafe.delivery_price * 100,
    min_free_delivery_total: cafe.min_free_delivery_total * 100
  };
};

@Injectable()
export class CoffeeHouseDataProvider extends CommonDataProvider<Cafe> {
  private cafesUrl = environment.apiUrl + '/admin/cafes';
  private geocoder = new google.maps.Geocoder();
  private placesAutocompleteService = new google.maps.places.AutocompleteService();

  constructor(
    http: HttpClient,
    private fileUploadService: FileUploadService,
    private router: Router,
    private companyService: CompanyService,
    loaderService: LoaderService
  ) {
    super(http, loaderService);
  }

  autocomplete(query: string): Observable<google.maps.places.AutocompletePrediction[]> {
    return new Observable(subscriber => {
      this.placesAutocompleteService.getPlacePredictions(
        {
          input: query,
          types: ['address']
        },
        (result, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            subscriber.next(result);
          } else {
            subscriber.next([]);
          }
        }
      );
    });
  }

  search(input: google.maps.GeocoderRequest): Observable<google.maps.GeocoderResult[]> {
    return new Observable(subscriber => {
      this.geocoder.geocode(input, (result, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          subscriber.next(result);
        } else {
          subscriber.error(status);
        }
      });
    });
  }

  getCafes({ companyId = this.companyService.COMPANY_ID, filter = '' }: { companyId?: string | number; filter?: string } = {}): Observable<
    ICommonList<Cafe>
  > {
    const params = {
      company_id: companyId,
      filter
    };
    return this.getList(this.cafesUrl, params);
  }

  getCafe(id: string | number): Observable<Cafe> {
    return this.http.get(`${this.cafesUrl}/${id}`).pipe(
      map((response: ICommonResponse) => {
        return transformCafeToShow(response.data);
      }),
      catchError((e: HttpErrorResponse) => {
        this.router.navigate(['admin/coffeehouses']);
        return this.handleError(e);
      })
    );
  }

  createCafe(cafe: Cafe): Observable<number> {
    cafe = transformCafeToSave(cafe);
    const body = JSON.stringify(cafe);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.cafesUrl, body, { headers }).pipe(
      map((data: ICommonResponse) => data.data),
      catchError(this.handleError)
    );
  }

  updateCafe(cafe: Partial<Cafe>) {
    cafe = transformCafeToSave(cafe);
    cafe.company = { id: this.companyService.COMPANY_ID };
    return this.http.patch(this.cafesUrl, cafe, { responseType: 'text' }).pipe(catchError(this.handleError));
  }

  deleteCafe(id: number) {
    return this.http.delete(`${this.cafesUrl}/${id}`).pipe(catchError(this.handleError));
  }

  hideCafe(id: number) {
    return this.http.post(`${this.cafesUrl}/${id}/hide`, null, { responseType: 'text' }).pipe(catchError(this.handleError));
  }

  showCafe(id: number) {
    return this.http.post(`${this.cafesUrl}/${id}/activate`, null, { responseType: 'text' }).pipe(catchError(this.handleError));
  }

  updateQrCodes(id: number) {
    return this.http.put(`${this.cafesUrl}/${id}/qrupdate`, null).pipe(catchError(this.handleError));
  }

  updateManagerPassword(cafeId: number, managerId: number, password: string) {
    return this.http
      .put(`${this.cafesUrl}/${cafeId}/managers/${managerId}/password`, {
        password
      })
      .pipe(catchError(this.handleError));
  }

  getStatistics(): Observable<CafeStatistic[]> {
    return this.http.get(`${this.cafesUrl}/statistics`).pipe(
      map((data: ICommonResponse) => data.data),
      catchError(this.handleError)
    );
  }

  getCafeManagers(id): Observable<ICommonList<Manager>> {
    return this.http.get(`${this.cafesUrl}/${id}/managers`).pipe(
      map((data: ICommonResponse) => {
        data.data.items = data.data.managers;
        return data.data;
      }),
      catchError(this.handleError)
    );
  }

  getFulfillmentMetadata(cafeId: number): Observable<FulfillmentMetadata> {
    return this.http.get(`${environment.apiUrl}/cafes/${cafeId}/fulfillment-metadata`).pipe(
      map((res: ICommonResponse) => res.data),
      catchError(this.handleError)
    );
  }

  dismissManager(cafeId, managerId) {
    return this.http
      .post(`${this.cafesUrl}/${cafeId}/managers/${managerId}/dismiss`, null, {
        responseType: 'text'
      })
      .pipe(catchError(this.handleError));
  }

  dismissInvite(cafeId, inviteId) {
    return this.http
      .post(`${this.cafesUrl}/${cafeId}/invites/${inviteId}/dismiss`, null, {
        responseType: 'text'
      })
      .pipe(catchError(this.handleError));
  }

  inviteManager(cafeId, email: string) {
    return this.http.post(`${this.cafesUrl}/${cafeId}/managers/invite`, { email }).pipe(
      map((data: ICommonResponse) => data.data),
      catchError(this.handleError)
    );
  }

  changeNotificationAllowance(cafeId, managerId, isNotificationsAllowed) {
    const data = {
      notificationsAllowed: isNotificationsAllowed
    };
    return this.http.put(`${this.cafesUrl}/${cafeId}/managers/${managerId}/notifications`, data).pipe(catchError(this.handleError));
  }

  createManager(cafeId: number, manager: ManagerInfoToCreate): Observable<Manager> {
    return this.postAction(`${this.cafesUrl}/${cafeId}/managers`, manager);
  }

  getCafesQRCodesData(company_id: string) {
    this.loaderService.show();
    return this.http.get(`${this.cafesUrl}/qrs`, { params: { company_id } }).pipe(
      map((response: ICommonResponse) => {
        this.loaderService.hide();
        return response.data;
      }),
      catchError(this.handleError)
    );
  }

  getQRCode(cafeId: string, mealId: string, size?: string) {
    return this.http
      .get(`${this.cafesUrl}/${cafeId}/meals/${mealId}/qr`, {
        params: { size },
        responseType: 'blob'
      })
      .pipe(
        map((code: Blob) => code),
        catchError(this.handleError)
      );
  }

  deleteQRCode(cafeId: string, mealId: string) {
    this.loaderService.show();

    return this.http.delete(`${this.cafesUrl}/${cafeId}/meals/${mealId}/qr`).pipe(
      map((response: ICommonResponse) => {
        return 'OK';
      }),
      catchError(this.handleError),
      finalize(() => this.loaderService.hide())
    );
  }
}
