import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { StompService } from '@stomp/ng2-stompjs';
import { webSocketHeaders } from '../../shared/helpers/stomp-ws/stomp.config';

interface IResponse {
  data: {
    token: {
      access_token: string;
    };
    user: {
      admin: boolean;
      email: string;
      first_name: string;
      id: number;
      last_name: string;
      phone: string;
      show_customer_data: boolean;
      super_manager: boolean;
    };
  };
}

@Injectable()
export class RecoveryService {
  private subject = new Subject<void>();

  constructor(private http: HttpClient, private rxStompService: StompService) {}

  private handleSuccess = (response: IResponse) => {
    localStorage.setItem('token', response.data.token.access_token);
    this.rxStompService.configure({
      connectHeaders: webSocketHeaders()
    });
    localStorage.setItem(
      'current_user',
      JSON.stringify({
        ...response.data.user
      })
    );
    this.subject.next();
    localStorage.removeItem('recoveryEmail');
  };

  sendEmail = (email: string): Observable<void> => {
    return this.http
      .put(`${environment.apiUrl}/password-reminder`, { email })
      .pipe(map(() => localStorage.setItem('recoveryEmail', email)));
  };
  confirm = data => {
    return this.http.put(`${environment.apiUrl}/users/password`, data).pipe(map(this.handleSuccess));
  };
}
