import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { pluck } from 'rxjs/operators';
import { CompanyOptionName } from '../../models/company-option.model';

@Injectable()
export class CompanyOptionNamesDataProvider {
  private baseUrl = environment.apiUrl + '/admin/company-option-names';

  constructor(private http: HttpClient) {}

  getOptionNames() {
    return this.http.get(this.baseUrl).pipe(pluck('data')) as Observable<CompanyOptionName[]>;
  }
}
