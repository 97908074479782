import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LoaderService } from '../loader.service';
import { Permission } from '../../models/permission.model';
import { Company } from '../../models/company.model';

export interface ICommonResponse {
  data: any;
}

export interface ICommonList<T> {
  items: T[];
  companies: Company[];
  permission: Permission;
}

interface ICommonListResponse<T> {
  data: ICommonList<T>;
}

@Injectable()
export class CommonDataProvider<T> {
  constructor(protected http: HttpClient, public loaderService: LoaderService) {}

  protected handleError = (error: HttpErrorResponse | any) => {
    let errMsg: string;
    if (error instanceof HttpErrorResponse) {
      const body = error;
      const err = body.error || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    this.loaderService.hide();
    return observableThrowError(errMsg);
  };

  protected getList(url: string, params?: any): Observable<ICommonList<T>> {
    this.loaderService.show();
    return this.http.get(url, { params }).pipe(
      map((data: ICommonListResponse<T>) => {
        if (Array.isArray(data.data)) {
          data.data.items = data.data;
        } else {
          const collectionKey = Object.keys(data.data)
            .filter(k => k !== 'permission')
            .pop();
          data.data.items = data.data[collectionKey];
        }
        this.loaderService.hide();
        return data.data;
      }),
      catchError(this.handleError)
    );
  }

  protected getCustomList<TList>(url: string, params?: any): Observable<ICommonList<TList>> {
    this.loaderService.show();
    return this.http.get(url, { params }).pipe(
      map((data: ICommonListResponse<TList>) => {
        data.data.items = data.data[0];
        this.loaderService.hide();
        return data.data;
      }),
      catchError(this.handleError)
    );
  }

  protected getItem(url: string, params?: any): Observable<T> {
    this.loaderService.show();
    return this.http.get(url, { params }).pipe(
      map((data: ICommonResponse) => {
        this.loaderService.hide();
        return data.data;
      }),
      catchError(this.handleError)
    );
  }

  protected postAction<TAction>(url: string, body?: any): Observable<TAction> {
    return this.http.post(url, body).pipe(
      map((data: ICommonResponse) => (data && data.data ? data.data : data)),
      catchError(this.handleError)
    );
  }

  protected putAction<TAction>(url: string, body?: any): Observable<TAction> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.put(url, body, { headers }).pipe(
      map((data: ICommonResponse) => (data && data.data ? data.data : data)),
      catchError(this.handleError)
    );
  }

  protected patchAction<TAction>(url: string, body?: any): Observable<TAction> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.patch(url, body, { headers }).pipe(
      map((data: ICommonResponse) => (data && data.data ? data.data : data)),
      catchError(this.handleError)
    );
  }

  protected deleteAction<TAction>(url: string, params?: any): Observable<TAction> {
    return this.http.delete(url, { params }).pipe(
      map((data: ICommonResponse) => (data && data.data ? data.data : data)),
      catchError(this.handleError)
    );
  }
}
