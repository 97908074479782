import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { LoaderService } from '../loader.service';
import { Company } from '../../models/company.model';
import { CommonDataProvider } from './common.data-provider';
import { catchError, map } from 'rxjs/operators';
import {
  IAvailableGateway,
  IMerchantsInfo,
  IMondidoMerchant,
  ISwishMerchant,
  IUnitellerMerchant,
  IUnsavedMondidoMerchant,
  IUnsavedSwishMerchant,
  IUnsavedUnitellerMerchant,
  PaymentMerchants
} from '../../models/merchants.model';

type MerchantModel<T extends PaymentMerchants> = T extends PaymentMerchants.SWISH
  ? ISwishMerchant
  : T extends PaymentMerchants.MONDIDO
  ? IMondidoMerchant
  : T extends PaymentMerchants.UNITELLER
  ? IUnitellerMerchant
  : never;

@Injectable()
export class MerchantsDataProvider extends CommonDataProvider<Company> {
  private companiesUrl = environment.apiUrl + '/admin/companies';

  constructor(http: HttpClient, loaderService: LoaderService) {
    super(http, loaderService);
  }

  getMerchants(companyId: number): Observable<IMerchantsInfo> {
    return this.http
      .get(`${this.companiesUrl}/${companyId}/merchants`)
      .pipe(map((value: { merchants: IMerchantsInfo }) => value.merchants));
  }

  saveMondidoData(companyId: number, params: Omit<IUnsavedMondidoMerchant, 'payment_gateway'>) {
    const body: IUnsavedMondidoMerchant = {
      ...params,
      payment_gateway: PaymentMerchants.MONDIDO
    };

    return this.http.post(`${this.companiesUrl}/${companyId}/merchant`, body);
  }

  updateMondidoData(companyId: number, merchantId: number, params: Omit<IUnsavedMondidoMerchant, 'payment_gateway'>) {
    const body: IUnsavedMondidoMerchant = {
      ...params,
      payment_gateway: PaymentMerchants.MONDIDO
    };

    return this.http.put(`${this.companiesUrl}/${companyId}/merchant/${merchantId}`, body);
  }

  saveUnitellerData(companyId: number, params: Omit<IUnsavedUnitellerMerchant, 'payment_gateway'>) {
    const body: IUnsavedUnitellerMerchant = {
      ...params,
      payment_gateway: PaymentMerchants.UNITELLER
    };

    return this.http.post(`${this.companiesUrl}/${companyId}/merchant`, body);
  }

  updateUnitellerData(companyId: number, merchantId: number, params: Omit<IUnsavedUnitellerMerchant, 'payment_gateway'>) {
    const body: IUnsavedUnitellerMerchant = {
      ...params,
      payment_gateway: PaymentMerchants.UNITELLER
    };

    return this.http.put(`${this.companiesUrl}/${companyId}/merchant/${merchantId}`, body);
  }

  getUnitellerTaxModes() {
    return this.http.get(`${environment.apiUrl}/payment/uniteller/tax-codes`).pipe(
      catchError(this.handleError),
      map((value: { code: number; description: string }[]) => value)
    );
  }

  saveSwishData(companyId: number, params: Omit<IUnsavedSwishMerchant, 'payment_gateway'>) {
    const body: IUnsavedSwishMerchant = {
      ...params,
      payment_gateway: PaymentMerchants.SWISH
    };

    return this.http.post(`${this.companiesUrl}/${companyId}/merchant`, body);
  }

  updateSwishData(companyId: number, merchantId: number, params: Omit<IUnsavedSwishMerchant, 'payment_gateway'>) {
    const body: IUnsavedSwishMerchant = {
      ...params,
      payment_gateway: PaymentMerchants.SWISH
    };

    return this.http.put(`${this.companiesUrl}/${companyId}/merchant/${merchantId}`, body);
  }

  getAvailablePaymentGateways(companyId: number) {
    return this.http.get(`${this.companiesUrl}/${companyId}/payment-gateway`).pipe(
      catchError(this.handleError),
      map((value: IAvailableGateway[]) => value)
    );
  }

  removeMerchantSettings(companyId: number, merchantId: number) {
    return this.http.delete(`${this.companiesUrl}/${companyId}/merchant/${merchantId}`).pipe(catchError(this.handleError));
  }

  quicklySetupMerchant<T extends PaymentMerchants>(companyId: number, merchantType: T): Observable<MerchantModel<T>> {
    return this.http
      .post(`${this.companiesUrl}/${companyId}/merchant?default=true`, {
        payment_gateway: merchantType
      })
      .pipe(
        catchError(this.handleError),
        map((value: { data: MerchantModel<T> }) => value.data)
      );
  }
}
