import { Meal } from '../../core/models/meal.model';

export const transformMealToSave = meal => {
  return convertPrice(meal, (p: number) => p * 100);
};

export const transformMealToShow = (meal): Meal => {
  return convertPrice(meal, (p: number) => p / 100);
};

function convertPrice(meal: Meal, operation: (p: number) => number) {
  meal.variation_groups = meal.variation_groups.map(variationGroup => ({
    ...variationGroup,
    variations: variationGroup.variations.map(variation => ({
      ...variation,
      price: operation(variation.price),
      old_price: operation(variation.old_price)
    }))
  }));
  meal.addon_groups = meal.addon_groups.map(addon => ({
    ...addon,
    addon_group_items: addon.addon_group_items.map(item => {
      return {
        ...item,
        addon: {
          ...item.addon,
          price: operation(item.addon.price)
        }
      };
    })
  }));
  meal.price = operation(meal.price);
  meal.old_price = operation(meal.old_price);

  return meal;
}
