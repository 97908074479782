import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { DeliveryRate, DeliveryRateData, RadialDeliveryRates } from '../models/delivery-rates';

@Injectable()
export class DeliveryRatesService {
  readonly url = environment.apiUrl + '/admin/companies';
  readonly urlGlobal = environment.apiUrl + '/admin/delivery-rates';

  constructor(private http: HttpClient) {}

  getDeliveryRates = (companyId: number): Observable<DeliveryRateData> => {
    return this.http.get(`${this.url}/${companyId}/delivery-rates`).pipe(pluck('data'));
  };

  createDeliveryRates = (companyId: number, data: DeliveryRate[]): Observable<RadialDeliveryRates> => {
    return this.http.post(`${this.url}/${companyId}/delivery-rates`, data).pipe(pluck('data'));
  };

  updateDeliveryRates = (companyId: number, data: DeliveryRate[]): Observable<DeliveryRateData[]> => {
    return this.http.put(`${this.url}/${companyId}/delivery-rates`, data).pipe(pluck('data'));
  };

  deleteDeliveryRate = (companyId: number, rateId: number) => {
    return this.http.delete(`${this.url}/${companyId}/delivery-rates/${rateId}`);
  };

  createGlobalDeliveryRates = (data: DeliveryRate[]): Observable<RadialDeliveryRates> => {
    return this.http.post(this.urlGlobal, data).pipe(pluck('data'));
  };

  updateGlobalDeliveryRates = (data: DeliveryRate[]): Observable<DeliveryRateData[]> => {
    return this.http.put(this.urlGlobal, data).pipe(pluck('data'));
  };

  deleteGlobalDeliveryRate = (rateId: number) => {
    return this.http.delete(`${this.urlGlobal}/${rateId}`);
  };
}
