import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoaderService } from '../services/loader.service';

declare var $: any;

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  constructor(private loaderService: LoaderService) {}

  public static obtainToken() {
    return localStorage.getItem('token') && `Bearer ${localStorage.getItem('token')}`;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loaderService.show();

    let headers = req.headers.set('language', 'en'); // set En language as a default.
    const token = AuthenticationInterceptor.obtainToken();
    if (!!token) {
      headers = headers.set('Authorization', token);
    }
    const changedReq = req.clone({
      withCredentials: true,
      headers
    });

    return next.handle(changedReq).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event.type === HttpEventType.Response) {
            this.loaderService.hide();
          }
        },
        errRes => {
          if (changedReq.responseType && changedReq.responseType === 'text') {
            errRes.error = JSON.parse(errRes.error);
          }

          let message = errRes.error.message || errRes.statusText;

          if (errRes.status === 403) {
            console.warn('The request has been made with wrong credentials');
            return;
          }

          if (errRes.status === 502 || (errRes.status === 0 && errRes.message.indexOf('Http failure response') > -1)) {
            message = 'Server is unavailable';
          }
          this.loaderService.hide();
          $.notify(
            {
              icon: 'pe-7s-attention',
              title: '<strong>            Error from server:</strong>  ',
              message
            },
            {
              delay: 5000,
              type: 'danger',
              timer: 1000,
              placement: {
                from: 'top',
                align: 'right'
              }
            }
          );
        }
      )
    );
  }
}
