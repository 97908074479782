import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Product } from '../../models/product.model';
import { CommonDataProvider, ICommonList } from './common.data-provider';

@Injectable()
export class ProductsDataProvider extends CommonDataProvider<Product> {
  private productUrl = environment.apiUrl + '/admin/products';

  getProducts(): Observable<ICommonList<Product>> {
    return this.getList(this.productUrl);
  }

  getProduct(id: string): Observable<Product> {
    return this.getItem(this.productUrl + '/' + id);
  }

  createProduct(product: Product): Observable<number> {
    const body = JSON.stringify(product);
    return this.postAction(this.productUrl, body);
  }

  updateProduct(product: Product) {
    const body = JSON.stringify(product);
    return this.patchAction(this.productUrl, body);
  }

  deleteProduct(id: number) {
    return this.deleteAction(this.productUrl + '/' + id);
  }
}
