import { StompConfig } from '@stomp/ng2-stompjs';
import * as SockJS from 'sockjs-client';
import { environment } from '../../../../environments/environment';
import { AuthenticationInterceptor } from '../../../core/interceptors/auth.interceptor';

export const webSocketHeaders = () => {
  const authHeader = AuthenticationInterceptor.obtainToken();
  return !!authHeader ? { Authorization: authHeader } : {};
};

export const stompConfig = (): StompConfig => {
  return {
    // Which server?
    url: () => new SockJS(environment.wsUrl),

    // Headers
    // Typical keys: login, passcode, host
    headers: webSocketHeaders(),
    // How often to heartbeat?
    // Interval in milliseconds, set to 0 to disable
    heartbeat_in: 20000, //  every 20 seconds
    heartbeat_out: 0, // disabled

    // Wait in milliseconds before attempting auto reconnect
    // Set to 0 to disable
    // Typical value 5000 (5 seconds)
    reconnect_delay: 5000,

    // Will log diagnostics on console
    debug: false
  };
};
