import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { LoaderService } from '../loader.service';
import { Company } from '../../models/company.model';
import { Manager, ManagerInfoToCreate } from '../../models/manager.model';
import { CommonDataProvider, ICommonList } from './common.data-provider';
import { catchError, map, tap } from 'rxjs/operators';
import { LoginService } from '../login.service';

@Injectable()
export class CompaniesDataProvider extends CommonDataProvider<Company> {
  private companiesUrl = environment.apiUrl + '/admin/companies';
  private companiesListSubject = new ReplaySubject<ICommonList<Company>>(1);

  constructor(http: HttpClient, loaderService: LoaderService, loginService: LoginService) {
    super(http, loaderService);
    this.updateCompaniesList();
    loginService.getUserChangedSubject().subscribe(() => {
      this.updateCompaniesList();
    });
  }

  private updateCompaniesList() {
    this.getCompanies().subscribe(companies => {
      this.companiesListSubject.next(companies);
    });
  }

  getCompanies(): Observable<ICommonList<Company>> {
    return this.getList(this.companiesUrl);
  }

  getCompaniesListSubscription(): Observable<ICommonList<Company>> {
    return this.companiesListSubject;
  }

  withListUpdate<T>(observable: Observable<T>) {
    return observable.pipe(tap(() => this.updateCompaniesList()));
  }

  getCompany(id): Observable<Company> {
    return this.getItem(`${this.companiesUrl}/${id}`);
  }

  updateCompany(id: number, body: Partial<Company>): Observable<Company> {
    return this.withListUpdate(this.putAction<Company>(`${this.companiesUrl}/${id}`, body));
  }

  updateManagerPassword(companyId: number, managerId: number, password: string) {
    return this.withListUpdate(
      this.putAction<Company>(`${this.companiesUrl}/${companyId}/managers/${managerId}/password`, { password })
    );
  }

  createCompany(body: Partial<Company>): Observable<Company> {
    return this.withListUpdate(this.postAction<Company>(`${this.companiesUrl}/`, body));
  }

  hideCompany(id: number): Observable<any> {
    return this.withListUpdate(this.http.post(`${this.companiesUrl}/${id}/hide`, {}).pipe(catchError(this.handleError)));
  }

  activateCompany(id: number): Observable<any> {
    return this.withListUpdate(this.http.post(`${this.companiesUrl}/${id}/activate`, {}).pipe(catchError(this.handleError)));
  }

  removeCompany(id: number): Observable<any> {
    return this.withListUpdate(this.http.delete(`${this.companiesUrl}/${id}`).pipe(catchError(this.handleError)));
  }

  getCompanyManagers(id): Observable<ICommonList<Manager>> {
    this.loaderService.show();
    return this.http.get(`${this.companiesUrl}/${id}/managers`).pipe(
      map((data: any) => {
        data.data.items = data.data.managers;
        this.loaderService.hide();
        return data.data;
      }),
      catchError(this.handleError)
    );
  }

  dismissManager(companyId, managerId) {
    return this.postAction<any>(`${this.companiesUrl}/${companyId}/managers/${managerId}/dismiss`, null);
  }

  dismissInvite(companyId, inviteId) {
    return this.postAction<any>(`${this.companiesUrl}/${companyId}/invites/${inviteId}/dismiss`, null);
  }

  inviteManager(companyId, email) {
    return this.postAction<any>(`${this.companiesUrl}/${companyId}/managers/invite`, { email });
  }

  createManager(companyId: number, manager: ManagerInfoToCreate): Observable<Manager> {
    return this.postAction(`${this.companiesUrl}/${companyId}/managers`, manager);
  }
}
