import { User } from './user.model';
import { Cafe, DirectionTypeEnum } from './cafe.model';
import { DICTIONARY } from './orders.dictionary';
import { Refund } from './refund.model';

export class Order {
  id: number;
  cafe: Cafe;
  user: User;
  items: OrderItem[];
  service_items: OrderServiceItem[];
  refunds: Refund[];
  direction: DirectionTypeEnum;
  canceled: boolean;
  paid: boolean;
  state: string;
  created_at: string;
  delivery_date: string;
  paid_at: string;
  close_at: string;
  total_price: number;
  vat: number;
  payment_type: string;
  paid_by_free: number;
  repeat: boolean;
  preparation_time: number;
  asap: boolean;
  fulfillment: Fulfillment;
  total_refunded: number;
  tracking_number?: string;
}

export enum OrderType {
  takeaway = 'TAKEAWAY',
  delivery = 'DELIVERY',
  eat_here = 'EAT_HERE'
}

export function getNextStateForOrder(order: Order) {
  const state = order.state;

  switch (state) {
    case DICTIONARY.state.NEW:
      return 'accept';
    case DICTIONARY.state.ACCEPTED:
      return 'ready';
    case DICTIONARY.state.PREPARED:
      return 'close';
  }
}

export class Fulfillment {
  full_name: string;
  address_line: string;
  entry_code: string;
  apartment_no: string;
  floor: string;
  delivery_instruction: string;
  phone: string;
  postal_code: string;
  latitude: number;
  longitude: number;
}

export class OrderItem {
  id: number;
  article: any;
  variations: {
    id: number;
    price: number;
    removed: boolean;
    name: string;
    description: string;
    is_default: boolean;
    meal_id: number;
    var_group: string;
  }[];
  addons: {
    id: number;
    price: number;
    removed: boolean;
    name: string;
  }[];
  order_id: number;
  comment: string;
  item_price: number;
  vat: number;
  number_of_items: number;
  number_of_free_items: 0;
  number_of_refunded_items: 0;
  number_of_refunded_free_items: 0;
  total_refunded: 0;
}

export interface OrderServiceItem {
  id: number;
  price: number;
  vat: number;
  service_type: string;
  type: string;
  total_refunded: 0;
}

export class RejectRequest {
  comment: string;
}
