import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError as observableThrowError } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { LoaderService } from '../loader.service';
import { CommonDataProvider } from './common.data-provider';
import { CompanyService } from '../company.service';
import { FileUploadService } from '../file-upload.service';

declare var $: any;

interface IImageResponse {
  url: string;
}

@Injectable()
export class PicturesDataProvider extends CommonDataProvider<any> {
  private urlGetters = {
    meal: (): string => environment.apiUrl + '/admin/files/company/' + this.companyService.COMPANY_ID + '/meals',
    category: (): string => environment.apiUrl + '/admin/files/company/' + this.companyService.COMPANY_ID + '/categories',
    company_logo: (): string => environment.apiUrl + '/admin/files/company/' + this.companyService.COMPANY_ID + '',
    company_bg: (): string => environment.apiUrl + '/admin/files/company/' + this.companyService.COMPANY_ID + ''
  };
  constructor(
    http: HttpClient,
    private fileUploadService: FileUploadService,
    private companyService: CompanyService,
    loaderService: LoaderService
  ) {
    super(http, loaderService);
  }

  createPicture(picture: string, type: 'meal' | 'category' | 'company_logo' | 'company_bg'): Observable<IImageResponse> {
    const url = this.urlGetters[type]();
    const pictureFile = this.fileUploadService.b64toFile(picture);
    if (pictureFile.size > environment.maxImageSize) {
      $.notify(
        {
          icon: 'pe-7s-attention',
          title: '<strong>            Error :</strong> ',
          message: 'The image is too big. It should be less than 10 MB.'
        },
        {
          delay: 5000,
          type: 'danger',
          timer: 1000,
          placement: {
            from: 'top',
            align: 'right'
          }
        }
      );
      return observableThrowError('The image is too big. It should be less than 10 MB.');
    }
    const formData: FormData = new FormData();
    formData.append('file', pictureFile, 'picture' + new Date().valueOf());
    return this.postAction<IImageResponse>(url, formData);
  }

  deletePicture(pictureId, type: string) {
    const url = this.urlGetters[type]();
    return this.deleteAction(url);
  }
}
