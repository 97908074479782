import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { LoaderService } from '../loader.service';
import { Category } from '../../models/category.model';
import { Permission } from '../../models/permission.model';
import { CommonDataProvider, ICommonList } from './common.data-provider';
import { CompanyService } from '../company.service';

interface ICommonResponse {
  data: any;
}

export interface ICategoriesResponse {
  categories: Category[];
  permission: Permission;
}

@Injectable()
export class CategoriesDataProvider extends CommonDataProvider<Category> {
  private categoriesUrl = `${environment.apiUrl}/admin/categories`;

  constructor(http: HttpClient, private companyService: CompanyService, loaderService: LoaderService) {
    super(http, loaderService);
  }

  getAllCategories(): Observable<ICategoriesResponse> {
    return this.http.get(`${this.categoriesUrl}?short=true&company_id=${this.companyService.COMPANY_ID}`).pipe(
      map((data: ICommonResponse) => data.data),
      catchError(this.handleError)
    );
  }

  getCategories(): Observable<ICommonList<Category>> {
    const params = {
      company_id: this.companyService.COMPANY_ID,
      short: false
    };
    return this.getList(this.categoriesUrl, params);
  }

  getCategoriesWithActiveMeals(): Observable<ICommonList<Category>> {
    const params = {
      company_id: this.companyService.COMPANY_ID,
      short: false,
      with_hidden_meals: false
    };
    return this.getList(this.categoriesUrl, params);
  }

  createCategory(category): Observable<Category> {
    return this.http
      .post(this.categoriesUrl, {
        ...category,
        company_id: this.companyService.COMPANY_ID
      })
      .pipe(
        map((data: ICommonResponse) => data.data),
        catchError((error: HttpErrorResponse | any) => {
          this.handleError(error);
          throw error;
        })
      );
  }

  deleteCategory(id: number) {
    return this.http.delete(`${this.categoriesUrl}/${id}`).pipe(catchError(this.handleError));
  }

  updateCategory(id: number, data: Partial<Category>) {
    return this.http
      .put(`${this.categoriesUrl}/${id}`, {
        ...data,
        company_id: this.companyService.COMPANY_ID
      })
      .pipe(
        map((response: ICommonResponse) => response.data),
        catchError((error: HttpErrorResponse | any) => {
          this.handleError(error);
          throw error;
        })
      );
  }

  saveCategoriesOrder(order: number[]) {
    return this.http
      .post(`${this.categoriesUrl}/order?company_id=${this.companyService.COMPANY_ID}`, order)
      .pipe(catchError(this.handleError));
  }
}
