import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { LoaderService } from '../loader.service';
import { CommonDataProvider } from './common.data-provider';
import { CompanyService } from '../company.service';
import { Addon, AddonGroup } from '../../models/addon.model';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class AddonsDataProvider extends CommonDataProvider<Addon> {
  private addonGroupsUrl = environment.apiUrl + '/admin/addon-groups';

  constructor(http: HttpClient, private companyService: CompanyService, loaderService: LoaderService) {
    super(http, loaderService);
  }

  getAddonGroups = (): Observable<AddonGroup[]> => {
    return this.http
      .get(this.addonGroupsUrl, {
        params: {
          company_id: String(this.companyService.COMPANY_ID)
        }
      })
      .pipe(
        map((items: AddonGroup[]) => {
          return items.map(group => {
            return {
              ...group,
              addon_group_items: group.addon_group_items.map(this.toViewAddon)
            };
          });
        }),
        catchError(this.handleError)
      );
  };

  bulkSaveAddonGroups(groups: AddonGroup[]): Observable<AddonGroup[]> {
    const backendGroups = groups.map(this.fromViewAddonGroup);

    return this.http.post(`${this.addonGroupsUrl}/bulk`, backendGroups).pipe(
      map((updatedGroups: AddonGroup[]) => updatedGroups.map(this.toViewAddonGroup)),
      catchError(this.handleError)
    );
  }

  deleteAddonGroup = (id: number) => {
    return this.http.delete(`${this.addonGroupsUrl}/${id}`).pipe(catchError(this.handleError));
  };

  updateGroupOrder = (id: number, array: number[]) => {
    return this.http
      .post(`${this.addonGroupsUrl}/order`, array, {
        params: { meal_id: id + '' }
      })
      .pipe(catchError(this.handleError));
  };

  toViewAddonGroup = (data: AddonGroup): AddonGroup => {
    return {
      ...data,
      addon_group_items: data.addon_group_items.map(this.toViewAddon)
    } as AddonGroup;
  };

  fromViewAddonGroup = (data: AddonGroup): AddonGroup => {
    return {
      ...data,
      addon_group_items: data.addon_group_items.map(this.fromViewAddon)
    } as AddonGroup;
  };

  toViewAddon(data: Addon) {
    return {
      ...data,
      addon: {
        ...data.addon,
        price: data.addon.price / 100
      }
    };
  }

  fromViewAddon(data: Addon) {
    return {
      ...data,
      addon: {
        ...data.addon,
        price: data.addon.price * 100
      }
    };
  }
}
