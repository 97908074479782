import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CommonDataProvider } from './common.data-provider';
import { LoaderService } from '../loader.service';
import { CompanyService } from '../company.service';
import {
  DeliveryZoneTypes,
  ISavedDeliveryZone,
  ITypedDeliveryZone,
  ITypedGeoZone,
  IUnsavedGeoZone,
  IUnsavedPostalCodeZone,
} from '../../models/delivery-zone.model';
import { combineLatest, Observable } from 'rxjs';
import { catchError, map, mapTo } from 'rxjs/operators';

@Injectable()
export class DeliveryZoneDataProvider extends CommonDataProvider<
  ISavedDeliveryZone
> {
  constructor(
    http: HttpClient,
    private companyService: CompanyService,
    loaderService: LoaderService
  ) {
    super(http, loaderService);
  }

  private getUrl(cafeId: number, zoneId?: number) {
    if (zoneId !== undefined) {
      return `${environment.apiUrl}/admin/cafes/${cafeId}/delivery-zones/${zoneId}`;
    } else {
      return `${environment.apiUrl}/admin/cafes/${cafeId}/delivery-zones`;
    }
  }

  private requestGeospatialZones(cafeId: number) {
    return this.http
      .get(
        `${environment.apiUrl}/admin/cafes/${cafeId}/geospatial-delivery-zones`
      )
      .pipe(map((resp: { data: ISavedDeliveryZone[] }) => resp.data));
  }

  private requestPostalCodeZones(cafeId: number) {
    return this.http
      .get(this.getUrl(cafeId))
      .pipe(map((resp: { data: ISavedDeliveryZone[] }) => resp.data));
  }

  requestCompanyDeliveryZones(
    companyId: number
  ): Observable<ITypedDeliveryZone[]> {
    return this.http
      .get(`${environment.apiUrl}/admin/companies/${companyId}/delivery-zones`)
      .pipe(
        map((resp: { data: ISavedDeliveryZone[] }) => {
          const eCommerceZones: ITypedDeliveryZone[] = resp.data.map(zone => ({
            ...zone,
            type: DeliveryZoneTypes.ECommerce,
          }));

          return eCommerceZones;
        })
      );
  }

  requestCafeDeliveryZones(cafeId: number): Observable<ITypedDeliveryZone[]> {
    return combineLatest([
      this.requestPostalCodeZones(cafeId),
      this.requestGeospatialZones(cafeId),
    ]).pipe(
      catchError(this.handleError),
      map(([postalCodeZones, geospatialZones]) => {
        const typedPostalCodeZones: ITypedDeliveryZone[] = postalCodeZones.map(
          zone => ({
            ...zone,
            type: DeliveryZoneTypes.PostalCodes,
          })
        );

        const typedGeospatialZones: ITypedDeliveryZone[] = geospatialZones.map(
          zone => ({
            ...zone,
            type: DeliveryZoneTypes.Geospatial,
          })
        );

        return [...typedPostalCodeZones, ...typedGeospatialZones];
      })
    );
  }

  getPostalCodeZone(
    cafeId: number,
    zoneId: number
  ): Observable<ISavedDeliveryZone & IUnsavedPostalCodeZone> {
    return this.http.get(this.getUrl(cafeId, zoneId)).pipe(
      catchError(this.handleError),
      map(
        (resp: { data: ISavedDeliveryZone & IUnsavedPostalCodeZone }) =>
          resp.data
      )
    );
  }

  getGeospatialZone(cafeId: number, zoneId: number): Observable<ITypedGeoZone> {
    return this.http
      .get(
        `${environment.apiUrl}/admin/cafes/${cafeId}/geospatial-delivery-zones/${zoneId}`
      )
      .pipe(
        catchError(this.handleError),
        map(this.mapGeoZone(DeliveryZoneTypes.Geospatial))
      );
  }

  getECommerceZone(
    companyId: number,
    zoneId: number
  ): Observable<ITypedGeoZone> {
    return this.http
      .get(
        `${environment.apiUrl}/admin/companies/${companyId}/delivery-zones/${zoneId}`
      )
      .pipe(
        catchError(this.handleError),
        map(this.mapGeoZone(DeliveryZoneTypes.ECommerce))
      );
  }

  createPostalCodeZone(
    cafeId: number,
    deliveryZone: IUnsavedPostalCodeZone
  ): Observable<ISavedDeliveryZone & IUnsavedPostalCodeZone> {
    return this.http.post(this.getUrl(cafeId), deliveryZone).pipe(
      catchError(this.handleError),
      map(
        (resp: { data: ISavedDeliveryZone & IUnsavedPostalCodeZone }) =>
          resp.data
      )
    );
  }

  createGeospatialZone(
    cafeId: number,
    deliveryZone: IUnsavedGeoZone
  ): Observable<ITypedGeoZone> {
    return this.http
      .post(
        `${environment.apiUrl}/admin/cafes/${cafeId}/geospatial-delivery-zones`,
        deliveryZone
      )
      .pipe(
        catchError(this.handleError),
        map(this.mapGeoZone(DeliveryZoneTypes.Geospatial))
      );
  }

  createECommerceZone(
    companyId: number,
    deliveryZone: IUnsavedGeoZone
  ): Observable<ITypedGeoZone> {
    return this.http
      .post(
        `${environment.apiUrl}/admin/companies/${companyId}/delivery-zones`,
        deliveryZone
      )
      .pipe(
        catchError(this.handleError),
        map(this.mapGeoZone(DeliveryZoneTypes.ECommerce))
      );
  }

  private mapGeoZone(zoneType: DeliveryZoneTypes) {
    return (resp: { data: ISavedDeliveryZone & IUnsavedGeoZone }) => {
      const typed = {
        ...resp.data,
        type: zoneType,
      };
      return typed;
    };
  }

  updateDeliveryZone(
    cafeId: number,
    deliveryZone: ISavedDeliveryZone
  ): Observable<ISavedDeliveryZone> {
    return this.http
      .put(this.getUrl(cafeId, deliveryZone.id), deliveryZone)
      .pipe(
        catchError(this.handleError),
        map((resp: { data: ISavedDeliveryZone }) => resp.data)
      );
  }

  updateGeospatialZone(
    cafeId: number,
    deliveryZone: IUnsavedGeoZone & { id: number }
  ): Observable<ITypedGeoZone> {
    return this.http
      .put(
        `${environment.apiUrl}/admin/cafes/${cafeId}/geospatial-delivery-zones/${deliveryZone.id}`,
        deliveryZone
      )
      .pipe(
        catchError(this.handleError),
        map(this.mapGeoZone(DeliveryZoneTypes.Geospatial))
      );
  }

  updateECommerceZone(
    companyId: number,
    deliveryZone: IUnsavedGeoZone & { id: number }
  ): Observable<ITypedGeoZone> {
    return this.http
      .put(
        `${environment.apiUrl}/admin/companies/${companyId}/delivery-zones/${deliveryZone.id}`,
        deliveryZone
      )
      .pipe(
        catchError(this.handleError),
        map(this.mapGeoZone(DeliveryZoneTypes.ECommerce))
      );
  }

  archiveDeliveryZone(
    zoneType: DeliveryZoneTypes,
    cafeOrCompanyId: number,
    zoneId: number
  ): Observable<void> {
    let url = '';
    switch (zoneType) {
      case DeliveryZoneTypes.ECommerce:
        url = `${environment.apiUrl}/admin/companies/${cafeOrCompanyId}/delivery-zones/${zoneId}`;
        break;
      case DeliveryZoneTypes.Geospatial:
        url = `${environment.apiUrl}/admin/cafes/${cafeOrCompanyId}/geospatial-delivery-zones/${zoneId}`;
        break;
      case DeliveryZoneTypes.PostalCodes:
        url = this.getUrl(cafeOrCompanyId, zoneId);
        break;
    }
    return this.http
      .delete(url)
      .pipe(catchError(this.handleError), mapTo(undefined));
  }
}
