import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';

import { environment } from '../../../environments/environment';
import { CommonDataProvider } from './data-providers/common.data-provider';
import { LoaderService } from './loader.service';
import { DirectionType, SourceType } from '../models/cafe.model';

const reportTypeTexts = ['by days', 'by meals'];

const contentTypeAndExtensionAccordance = {
  'application/zip': 'zip',
  'application/vnd.ms-excel': 'xlsx'
};

@Injectable()
export class ReportsService extends CommonDataProvider<any> {
  constructor(protected http: HttpClient, public loaderService: LoaderService) {
    super(http, loaderService);
  }

  getCompanyReport(id, data: { range: Date[] }, name?: string) {
    this.getReport(`${environment.apiUrl}/admin/companies/${id}/report`, this.dataToParams(data), 'Company', 'report', name);
  }

  getCompaniesReport(data: { range: Date[] }) {
    this.getReport(`${environment.apiUrl}/admin/report`, this.dataToParams(data), 'Companies', 'report');
  }

  getCompanyInvoice(id, data: { range: Date[] }, name?: string) {
    this.getReport(`${environment.apiUrl}/admin/companies/${id}/invoice`, this.dataToParams(data), 'Company', 'invoice', name);
  }

  getCompaniesInvoice(data: { range: Date[] }) {
    this.getReport(`${environment.apiUrl}/admin/invoices`, this.dataToParams(data), 'Companies', 'invoice');
  }

  getCompanyDeliveryReport(id, data: { range: Date[] }, name?: string) {
    this.getReport(
      `${environment.apiUrl}/admin/companies/${id}/delivery-report`,
      this.dataToParams(data),
      'Company',
      'delivery report',
      name
    );
  }

  getUsersReport(id, name?: string) {
    this.getUserReport(`${environment.apiUrl}/admin/companies/${id}/users/report`, 'User', name);
  }

  getCafeReport(
    id,
    data: {
      range: Date[];
      startAt: number;
      version: number;
      source: SourceType[];
      direction: DirectionType[];
    },
    name?: string
  ) {
    const reportType = reportTypeTexts[data.version];
    this.getReport(`${environment.apiUrl}/admin/cafes/${id}/report`, this.dataToParams(data, 'cafe'), 'Cafe', 'report', name, reportType);
  }

  private getUserReport(url, type: string, name?: string, reportType?: string) {
    this.http
      .get(url, {
        responseType: 'blob'
      })
      .pipe(
        tap((blob: Blob) => {
          let fileName = `report${reportType ? ` (${reportType})` : ''}.xlsx`;

          if (name) {
            fileName = `${type} ${name} ${fileName}`;
          } else {
            fileName = `${type} ${fileName}`;
          }
          saveAs(blob, fileName);
        }),
        catchError(this.handleError)
      )
      .subscribe();
  }

  private getReport(
    url,
    params: { from: string; to: string; day_split?: string; version?: string },
    type: string,
    fileNameParam: string,
    name?: string,
    reportType?: string
  ) {
    this.http
      .get(url, {
        params,
        observe: 'response',
        responseType: 'blob'
      })
      .pipe(
        tap(response => {
          const blob: Blob = response.body;
          const from = DateTime.fromISO(params.from).toFormat('LL/dd/yyyy');
          const to = DateTime.fromISO(params.to).toFormat('LL/dd/yyyy');
          const fileExtension = contentTypeAndExtensionAccordance[response.headers.get('Content-Type')];
          if (fileExtension === undefined) {
            console.warn(
              'Response has unknown "Content-Type", please add the "Content-Type" value to the "contentTypeAndExtensionAccordance"'
            );
          }
          let fileName = `${fileNameParam}${reportType ? ` (${reportType})` : ''}. Period from ${from} to ${to}.${fileExtension}`;

          if (name) {
            fileName = `${type} ${name} ${fileName}`;
          } else {
            fileName = `${type} ${fileName}`;
          }
          saveAs(blob, fileName);
        }),
        catchError(this.handleError)
      )
      .subscribe();
  }

  private dataToParams(
    data: {
      range: Date[];
      startAt?: number;
      version?: number;
      source?: SourceType[];
      direction?: DirectionType[];
    },
    type?: string
  ) {
    const { range, startAt, version, source, direction } = data;
    const from = DateTime.fromJSDate(range[0]).toFormat('yyyy-LL-dd');
    const to = DateTime.fromJSDate(range[1]).toFormat('yyyy-LL-dd');

    let params: {
      from: string;
      to: string;
      [key: string]: string | string[] | boolean;
    } = {
      from,
      to
    };

    if (type === 'cafe') {
      params = {
        ...params,
        start_time: String(startAt),
        version: version ? (version + 1).toString() : '1',
        source,
        direction
      };
    }

    return params;
  }
}
