import { DateTime } from 'luxon';

export type IWeek = {
  [key: string]: {
    start: string;
    end: string;
    day_of_week: number;
    day: string;
  };
};

export const SECONDS_IN_DAY = 86400;
export const SECONDS_IN_HOUR = 3600;
export const SECONDS_IN_MINUTE = 60;

export enum weekdays {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday'
}

export enum slotState {
  idle = 'idle',
  loading = 'loading',
  error = 'error',
  success = 'success',
  half = 'half'
}

export const week: IWeek = {
  mon: {
    start: '',
    end: '',
    day_of_week: 1,
    day: weekdays.Monday
  },
  tue: {
    start: '',
    end: '',
    day_of_week: 2,
    day: weekdays.Tuesday
  },
  wed: {
    start: '',
    end: '',
    day_of_week: 3,
    day: weekdays.Wednesday
  },
  thu: {
    start: '',
    end: '',
    day_of_week: 4,
    day: weekdays.Thursday
  },
  fri: {
    start: '',
    end: '',
    day_of_week: 5,
    day: weekdays.Friday
  },
  sat: {
    start: '',
    end: '',
    day_of_week: 6,
    day: weekdays.Saturday
  },
  sun: {
    start: '',
    end: '',
    day_of_week: 7,
    day: weekdays.Sunday
  }
};

export const getDuration = (secondStart: number, secondEnd: number): number => {
  const halfDay = SECONDS_IN_DAY / 2;

  const isEndAfterStart =
    (secondStart > secondEnd && secondStart <= halfDay && secondEnd < halfDay) ||
    (secondStart > secondEnd && secondStart >= halfDay && secondEnd <= halfDay) ||
    (secondStart > secondEnd && secondStart > halfDay && secondEnd > halfDay);

  if (isEndAfterStart) {
    return (SECONDS_IN_DAY - (secondStart - secondEnd)) % SECONDS_IN_DAY;
  }
  if (secondStart > secondEnd) {
    return (secondStart - secondEnd) % SECONDS_IN_DAY;
  }
  if (secondEnd > secondStart) {
    return (secondEnd - secondStart) % SECONDS_IN_DAY;
  }
  if (secondEnd - secondStart === 0) {
    return SECONDS_IN_DAY;
  }
};

export const getDatesBetweenDates = (startDate: Date, endDate: Date): string[] => {
  let dates: string[] = [];
  const theDate = new Date(new Date(startDate).setHours(0, 0, 0, 0));
  const theEndDate = new Date(new Date(endDate).setHours(0, 0, 0, 0));

  while (theDate < theEndDate) {
    dates = [...dates, timestampToStringFormat(Number(theDate))];
    theDate.setDate(theDate.getDate() + 1);
  }
  dates = [...dates, timestampToStringFormat(Number(theEndDate))];
  return dates;
};

export const secondsToTime = (value: number): string => {
  let hours: number | string = Math.floor(value / SECONDS_IN_HOUR);
  let minutes: number | string = Math.floor((value - hours * SECONDS_IN_HOUR) / SECONDS_IN_MINUTE);

  if (value > SECONDS_IN_DAY) {
    hours = Math.floor((value - SECONDS_IN_DAY) / SECONDS_IN_HOUR);
    minutes = Math.floor((value - SECONDS_IN_DAY - hours * SECONDS_IN_HOUR) / SECONDS_IN_MINUTE);
  }

  const half = hours >= 12 ? 'PM' : 'AM';

  if (half === 'PM' && hours === 12) {
    hours = 12;
  }
  if (half === 'AM' && hours === 12) {
    hours = 0;
  }
  if (hours >= 12) {
    hours = +hours - 12;
  }
  const modifyHours = hours < 10 ? '0' + hours : hours;
  const modifyMinutes = minutes < 10 ? '0' + minutes : minutes;
  return `${modifyHours}:${modifyMinutes} ${half}`;
};

export const getWeekDay = (num: number) => {
  return (['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'] as const)[num - 1];
};

export const padTime = (time: number): string => {
  return String(time).padStart(2, '0');
};
function convert12hTo24h(value: number, half: 'AM' | 'PM') {
  // We need special handling for this case since 12 AM/PM is ambiguous
  if (value === 12) {
    return half === 'AM' ? 0 : 12;
  } else {
    return half === 'AM' ? value : 12 + value;
  }
}

export const timeToMinutes = (fullTime: string): number => {
  const [time, half] = fullTime.split(' ') as [string, 'AM' | 'PM'];
  const [hours, minutes] = time.split(':').map(newTime => Number(newTime));
  const hourValue = convert12hTo24h(hours, half);
  return hourValue * 60 + minutes;
};

export const timeToSeconds = (fullTime: string): number => {
  const [time, half] = fullTime.split(' ');
  // tslint:disable-next-line:prefer-const
  let [hours, minutes] = time.split(':').map(newTime => Number(newTime));

  if (half === 'AM' && hours === 12) {
    hours = 0;
  }
  if (half === 'PM') {
    hours = hours === 12 ? 12 : hours + 12;
  }

  return hours * 60 * 60 + minutes * 60;
};

export const timestampToStringFormat = (timestamp: number): string => {
  const day = new Date(timestamp).getDate();
  const month = new Date(timestamp).getMonth() + 1;
  const year = new Date(timestamp).getFullYear();
  return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
};

export const translateDateToString = (date: Date): string => DateTime.fromJSDate(date).toFormat('yyyy-LL-dd');

export const translateStringToDate = (date: string): Date => new Date(date);
