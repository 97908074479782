import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, pluck } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { LoaderService } from '../loader.service';
import { LoginService } from '../login.service';
import { Pagination } from '../../models/pagination.model';
import { User } from '../../models/user.model';
import { CommonDataProvider, ICommonResponse } from './common.data-provider';
import { CompanyService } from '../company.service';

@Injectable()
export class UsersDataProvider extends CommonDataProvider<User> {
  private usersUrl = `${environment.apiUrl}/admin/users`;
  private customersUrl = `${environment.apiUrl}/admin/customers`;

  constructor(http: HttpClient, private companyService: CompanyService, private loginService: LoginService, loaderService: LoaderService) {
    super(http, loaderService);
  }

  getUsers(q: string, page: number, withAnonymous: boolean, per_page = 10): Observable<Pagination<User>> {
    const params = new HttpParams()
      .set('q', q)
      .set('company_id', this.companyService.COMPANY_ID.toString())
      .set('page', page.toString())
      .set('with_anonymous', withAnonymous.toString())
      .set('per_page', per_page.toString());

    this.loaderService.show();
    return this.http.get(this.usersUrl, { params }).pipe(
      map((d: Pagination<User>) => {
        this.loaderService.hide();
        return d;
      }),
      catchError(this.handleError)
    );
  }

  getCustomers(q: string, page: number, per_page = 10): Observable<Pagination<User>> {
    const params = new HttpParams()
      .set('q', q)
      .set('company_id', this.companyService.COMPANY_ID.toString())
      .set('page', page.toString())
      .set('per_page', per_page.toString());

    this.loaderService.show();
    return this.http.get(this.customersUrl, { params }).pipe(
      map((d: Pagination<User>) => {
        this.loaderService.hide();
        return d;
      }),
      catchError(this.handleError)
    );
  }

  deleteUser(id: number) {
    return this.http.delete(`${this.usersUrl}/${id}`).pipe(catchError(this.handleError));
  }

  deleteCustomer(id: number) {
    return this.http.delete(`${this.customersUrl}/${id}`).pipe(catchError(this.handleError));
  }

  getUser() {
    const user = JSON.parse(localStorage.getItem('current_user'));
    if (user.id && user.permission) {
      return of(user);
    } else {
      return this.http.get(`${this.usersUrl}/me`).pipe(pluck('data'), catchError(this.handleError));
    }
  }

  updateUser(user: Partial<User>) {
    return this.http.put(`${this.usersUrl}/${user.id}`, user).pipe(
      map((response: ICommonResponse) => {
        console.log(response);
        localStorage.setItem('current_user', JSON.stringify(response.data));
      }),
      catchError(this.handleError)
    );
  }

  updatePassword(id: number, password: string) {
    return this.postAction(`${this.usersUrl}/${id}/password`, {
      password
    });
  }

  isUserAdmin() {
    const user = JSON.parse(localStorage.getItem('current_user'));

    if (this.loginService.isUserLoggedInByCredentials) {
      return of(user.admin);
    } else {
      return this.http.get(`${this.usersUrl}/me`).pipe(
        map((response: ICommonResponse) => {
          localStorage.setItem('current_user', JSON.stringify({ ...response.data, company: user.company }));
          return response.data.admin;
        }),
        catchError(this.handleError)
      );
    }
  }

  isSuperManager() {
    const user = JSON.parse(localStorage.getItem('current_user'));

    if (this.loginService.isUserLoggedInByCredentials) {
      return of(user.super_manager);
    } else {
      return this.http.get(`${this.usersUrl}/me`).pipe(
        map((response: ICommonResponse) => {
          localStorage.setItem('current_user', JSON.stringify({ ...response.data, company: user.company }));
          return response.data.super_manager;
        }),
        catchError(this.handleError)
      );
    }
  }
}
