import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { PushNotification } from '../../models/push-notification.model';
import { User } from '../../models/user.model';
import { CommonDataProvider } from './common.data-provider';

@Injectable()
export class PushNotificationDataProvider extends CommonDataProvider<User> {
  private pushNotificationUrl = environment.apiUrl + '/admin/pushnotification';

  createPushNotification(pushNotification: PushNotification) {
    const body = JSON.stringify(pushNotification);
    return this.putAction(this.pushNotificationUrl, body);
  }
}
