import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Offer } from '../../models/offer.model';
import { CommonDataProvider, ICommonList, ICommonResponse } from './common.data-provider';
import { CompanyService } from '../company.service';
import { LoaderService } from '../loader.service';

@Injectable()
export class OffersDataProvider extends CommonDataProvider<Offer> {
  private offersUrl = environment.apiUrl + '/admin/offers';

  constructor(http: HttpClient, private companyService: CompanyService, loaderService: LoaderService) {
    super(http, loaderService);
  }

  getOffers(): Observable<ICommonList<Offer>> {
    return this.getList(this.offersUrl, {
      company_id: this.companyService.COMPANY_ID.toString()
    });
  }

  getOffer(id: string): Observable<Offer> {
    return this.getItem(this.offersUrl + '/' + id);
  }

  deleteOffer(id: number) {
    return this.http.delete(this.offersUrl + '/' + id);
  }

  disableOffer(id: number) {
    return this.http.put(this.offersUrl + '/' + id + '/disabled', {});
  }

  createOffer(offer: Offer) {
    return this.http.post(this.offersUrl, offer);
  }

  editOffer(offer: Offer) {
    return this.http.patch(this.offersUrl, offer);
  }
}
